import React, { useEffect } from 'react'

import Header from '../components/header'
import FeatureCard1 from '../components/feature-card1'
import './clients.css'
import Footer from '../components/footer'
import TestimonialCard1 from '../components/testimonial-card1'
import { Helmet } from 'react-helmet'

const Clients = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="clients-container">
      <Helmet>
        <title>Clientele</title>
        <meta property="og:title" content="Clientele" />
        <meta property="og:description" content="Raise is a leading supplier of construction materials and services in India. We are a one-stop solution for all your construction needs." />
        <meta property="og:image" content="/playground_assets/raiseLogo.jpeg" />
      </Helmet>
      <Header></Header>
      <div className="clients-features">
        <h1 className="clients-text">Glimpse into our Extensive Clientele</h1>
        <div className="grid grid-cols-4 gap-4">
        <FeatureCard1
            title="Fosroc Group"
            image_src="/playground_assets/fosroc.jpg"
            rootClassName="rootClassName"
          ></FeatureCard1>
          <FeatureCard1
            title="Shirke Group"
            image_src="http://www.duramaterials.in/images/shirke.png"
            rootClassName="rootClassName"
          ></FeatureCard1>
          <FeatureCard1
            title="IJM Infrastructure"
            image_src="http://www.duramaterials.in/images/ijm.png"
            rootClassName="rootClassName1"
          ></FeatureCard1>
          <FeatureCard1
            title="Modern Assets"
            image_src="http://www.duramaterials.in/images/modernasset.png"
            rootClassName="rootClassName2"
          ></FeatureCard1>
          <FeatureCard1
            title="KMV Projects"
            image_src="http://www.duramaterials.in/images/kmv.png"
            rootClassName="rootClassName3"
          ></FeatureCard1>
          <FeatureCard1
            title="RDC Concrete"
            image_src="http://www.duramaterials.in/images/rdc.png"
            rootClassName="rootClassName7"
          ></FeatureCard1>
          <FeatureCard1
            title="KNK Group"
            image_src="http://www.duramaterials.in/images/knk.png"
            rootClassName="rootClassName6"
          ></FeatureCard1>
          <FeatureCard1
            title="Starworth Projects"
            image_src="http://www.duramaterials.in/images/starworth.png"
            rootClassName="rootClassName5"
          ></FeatureCard1>
          <FeatureCard1
            title="Sanghi Cement"
            image_src="http://www.duramaterials.in/images/sanghicement.png"
            rootClassName="rootClassName4"
          ></FeatureCard1>
          <FeatureCard1
            title="Anjali Group"
            image_src="http://www.duramaterials.in/images/ANJALINEW.png"
            rootClassName="rootClassName8"
          ></FeatureCard1>
          <FeatureCard1
            title="NCC Group"
            image_src="http://www.duramaterials.in/images/ncc.png"
            rootClassName="rootClassName9"
          ></FeatureCard1>
          <FeatureCard1
            title="Nikitha Group"
            image_src="http://www.duramaterials.in/images/nikitha.png"
            rootClassName="rootClassName10"
          ></FeatureCard1>
          <FeatureCard1
            title="Qcrete"
            image_src="http://www.duramaterials.in/images/qcrete.png"
            rootClassName="rootClassName11"
          ></FeatureCard1>
          <FeatureCard1
            title="VCNR Group"
            image_src="http://www.duramaterials.in/images/vcnr.png"
            rootClassName="rootClassName12"
          ></FeatureCard1>
          <FeatureCard1
            title="Prime Cement"
            image_src="http://www.duramaterials.in/images/primecan.png"
            rootClassName="rootClassName13"
          ></FeatureCard1>
          <FeatureCard1
            title="Potential Consultants"
            image_src="http://www.duramaterials.in/images/potentialconsultants.png"
            rootClassName="rootClassName14"
          ></FeatureCard1>
          <FeatureCard1
            title="RK Group"
            image_src="http://www.duramaterials.in/images/rkgroup.png"
            rootClassName="rootClassName15"
          ></FeatureCard1>
        </div>
      </div>
        <Footer />
    </div>
  )
}

export default Clients
