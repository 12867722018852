import React, { useEffect } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { LocationOn, Call, Mail } from '@mui/icons-material'
import './contact.css'
import { Helmet } from 'react-helmet'

function Contact() {

    useEffect(()=>{
        window.scrollTo(0, 0)
    }, [])

    async function submitForm(){
        console.log("submitting form")
        console.log(document.getElementById("name").value)
        console.log(document.getElementById("email").value)
        console.log(document.getElementById("message").value)
        console.log(document.getElementById("company").value)
        const response = await fetch("https://dura-materials-default-rtdb.asia-southeast1.firebasedatabase.app/raise-leads.json", {
          method: "POST",
          body: JSON.stringify({
            name: document.getElementById("name").value,
            company: document.getElementById("company").value,
            email: document.getElementById("email").value,
            message: document.getElementById("message").value,
          })
        })
        const data = await response.json()
        console.log(data)
        if ("name" in data){
            document.getElementById("name").value = ""
            document.getElementById("company").value = ""
            document.getElementById("email").value = ""
            document.getElementById("message").value = ""
            alert("Thank you for contacting us. We will get back to you shortly.")
        } else {
            alert("There was an error submitting your form. Please try again.")
        }
      }

  return (
    <div className='contact-container'>
        <div style={{
            backgroundColor: '#1e293bff',
        }}>
            <Helmet>
        <title>Contact Us</title>
        <meta property="og:title" content="Contact Us" />
        <meta property="og:description" content="Raise is a leading supplier of construction materials and services in India. We are a one-stop solution for all your construction needs." />
        <meta property="og:image" content="/playground_assets/raiseLogo.jpeg" />
      </Helmet>
            <Header />
        </div>
        <div className='contact-details' id='contact-details'>
            <div className='contact-details-container'>
                <div className='contact-details-left'>
                    <div className='contact-details-left-container' style={{
                        paddingLeft: 30,
                        paddingRight: 30
                    }}>
                        <div className='contact-details-left-container-title'>
                            <h1>CONTACT US</h1>

                            <p className='contact-subline2'>
                                We are always happy to hear from you. Please
                                contact us for any queries or feedback.
                            </p>

                            <div className='contact-details-left-container-title-address'>
                                <div className="contact-item">
                                    <LocationOn style={{ fontSize: 40 }} />
                                    <h3>Address</h3>
                                    <p>
                                    102 Road No. 4, Defence Layout, Vidyaranyapura, Bengaluru, Karnataka
                                    560078
                                    </p>
                                </div>

                                <div className="contact-item">
                                    <Call style={{ fontSize: 40 }} />
                                    <h3>Phone</h3>
                                    <a href="tel:+918495042742">
                                        <p>+91 8495042742</p>
                                    </a>
                                </div>

                                <div className="contact-item">
                                    <Mail style={{ fontSize: 40 }} />
                                    <h3>Email</h3>
                                    <p>
                                        <a href='mailto:info@raisebeyondlimits.com'>
                                        Mail Us At: info@raisebeyondlimits.com
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='contact-details-right'>
                    <div className='contact-details-right-container'>
                        <div className='contact-details-right-container-title' style={{
                    paddingLeft: 60,
                    paddingRight: 60
                }}>
                            <h1>Get in Touch</h1>
                            <p>
                                We are always happy to hear from you. Please
                                contact us for any queries or feedback.
                            </p>
                            
                            <div action="https://divspree.io/f/xnqoqzjy" method="POST">
                                <div className='contact-details-right-container-title-input'>
                                    <input
                                        type='text'
                                        id='name'
                                        name='name'
                                        placeholder='Name'
                                        required
                                       
                                    />
                                    <input
                                        type='email'
                                        id='email'
                                        name='email'
                                        placeholder='Email'
                                        required
                                    />
                                    <input
                                        type='text'
                                        id='company'
                                        name='company'
                                        placeholder='You company name'
                                        required
                                    />                                    
                                    <textarea
                                        name='message' 
                                        id='message'
                                        placeholder='Message'
                                        required
                                    ></textarea>
                                    <button type='submit'>Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Contact