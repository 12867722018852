import React, { useEffect, useState } from 'react'
import GalleryCard1 from '../components/gallery-card1'
import { useParams } from 'react-router-dom'
import Header from '../components/header'
import Footer from '../components/footer'
import productsJson from '../products.json'
import './singleCategory.css'
import { parseCommandLine } from 'typescript'
import { Helmet } from 'react-helmet'

function SingleCategory() {

    const products = JSON.parse(JSON.stringify(productsJson))
  const products2 = {
    "Granite": [
      '/playground_assets/granites/granites2/Amba-White.png',
      '/playground_assets/granites/granites2/Asian-Top.png',
      '/playground_assets/granites/granites2/Astoria.png',
      '/playground_assets/granites/granites2/Cats-Eye.png',
      '/playground_assets/granites/granites2/Colombo-Juparana.png',
      '/playground_assets/granites/granites2/Colonial-White.png',
      '/playground_assets/granites/granites2/Coral-White.png',
      '/playground_assets/granites/granites2/Ghiblee-Gold.png',
      '/playground_assets/granites/granites2/Golden-Beach.png',
      '/playground_assets/granites/granites2/Golden-Oak.png',
      '/playground_assets/granites/granites2/Imperial-Gold.png',
      '/playground_assets/granites/granites2/Ivory-Brown.png',
      '/playground_assets/granites/granites2/Ivory-Chiffon.png',
      '/playground_assets/granites/granites2/Kashmir-Cream.png',
      '/playground_assets/granites/granites2/New-Imperial-Gold.png',
      '/playground_assets/granites/granites2/New-Kashmir-White.png',
      '/playground_assets/granites/granites2/Paradiso-Bash.png',
      '/playground_assets/granites/granites2/Paradiso-Classic.png',
      '/playground_assets/granites/granites2/Pink-Juparana.png',
      '/playground_assets/granites/granites2/Red-Multi-Colour.png',
      '/playground_assets/granites/granites2/River-White.png',
      '/playground_assets/granites/granites2/Rosewood.png',
      '/playground_assets/granites/granites2/Shivakshi-Gold.png',
      '/playground_assets/granites/granites2/Shivakshi-Pink.png',
      '/playground_assets/granites/granites2/Strawberry-Pink.png',
      '/playground_assets/granites/granites2/Thunder-White.png',
      '/playground_assets/granites/granites2/Viscon-White.png',
      '/playground_assets/granites/granites1/Arizona-Gold.png',
      '/playground_assets/granites/granites1/Black-Galaxy-Brush-Hammered.png',
      '/playground_assets/granites/granites1/Black-Galaxy-Honed.png',
      '/playground_assets/granites/granites1/Black-Galaxy-Flamed.png',
      '/playground_assets/granites/granites1/Black-Galaxy-Lapatro.png',
      '/playground_assets/granites/granites1/Black-Galaxy.png',
      '/playground_assets/granites/granites1/Black-Pearl.png',
      '/playground_assets/granites/granites1/Flash-Blue.png',
      '/playground_assets/granites/granites1/G20.png',
      '/playground_assets/granites/granites1/Impala-Black.png',
      '/playground_assets/granites/granites1/Imperial-Brown.png',
      '/playground_assets/granites/granites1/Indian-Black.png',
      '/playground_assets/granites/granites1/Kingfisher-Blue.png',
      '/playground_assets/granites/granites1/Lavender-Blue.png',
      '/playground_assets/granites/granites1/Moon-White.png',
      '/playground_assets/granites/granites1/New-Tropic-Brown.png',
      '/playground_assets/granites/granites1/Prada-Gold.png',
      '/playground_assets/granites/granites1/SK-Blue.png',
      '/playground_assets/granites/granites1/Spice-Black.png',
      '/playground_assets/granites/granites1/Star-Galaxy.png',
      '/playground_assets/granites/granites1/Steel-Grey.png',
      '/playground_assets/granites/granites3/Absolute-Black.png',
      '/playground_assets/granites/granites3/Coffee-Brown.png',
      '/playground_assets/granites/granites3/Khammam-Black.png',
      '/playground_assets/granites/granites3/Pegasus-Brown.png',
      '/playground_assets/granites/granites3/Sapphire-Brown.png',
      '/playground_assets/granites/granites3/Tan-Brown.png',
      '/playground_assets/granites/granites4/Bala-Flower.png',
      '/playground_assets/granites/granites4/Black-Magic.png',
      '/playground_assets/granites/granites4/Chima-Pink.png',
      '/playground_assets/granites/granites4/Crystal-Yellow-1.png',
      '/playground_assets/granites/granites4/Crystal-Yellow-2.png',
      '/playground_assets/granites/granites4/Desert-Brown.png',
      '/playground_assets/granites/granites4/French-Green.png',
      '/playground_assets/granites/granites4/Indian-Green-Marble.png',
      '/playground_assets/granites/granites4/Jhansi-Red.png',
      '/playground_assets/granites/granites4/Koliwada.png',
      '/playground_assets/granites/granites4/New-Imperial-Red.png',
      '/playground_assets/granites/granites4/P-White.png',
      '/playground_assets/granites/granites4/Pink-Panther.png',
      '/playground_assets/granites/granites4/Rajasthan-Black.png',
      '/playground_assets/granites/granites4/Raniwada.png',
      '/playground_assets/granites/granites4/Rosy-Pink.png',
      '/playground_assets/granites/granites4/Royal-Cream.png',
      '/playground_assets/granites/granites4/S-White.png',
      '/playground_assets/granites/granites4/Tiger-Skin.png',
      '/playground_assets/granites/granites4/Tomato-Red.png',
      '/playground_assets/granites/granites4/Z-Brown.png',
    ]
  }

    const categoryDescriptions = {
        "Pronil Waterproofing Solutions":
        "We at Raise are the sole importer and Super Stockiest for Pronil in India and we offer Pronil Waterproofing Solutions to provide the highest level of protection for your building's foundation and roof. Our solutions are designed to prevent any damage caused by moisture infiltration. We use the latest technology and high-quality materials to provide customized waterproofing solutions for commercial, industrial, and residential buildings.",
        "Carbon Fibers & Laminates":
            "Experience the power of carbon fibers and laminates in construction, where strength meets versatility. With unmatched tensile strength, lightweight composition, and exceptional resistance to corrosion, impact, and fatigue, carbon fibers redefine what's possible for your structures. Paired with the flexibility and rigidity of carbon fiber laminates, you can unleash limitless design potential while ensuring structural integrity.",
        "Construction Chemicals":
            "At Raise, we provide a range of construction chemicals that are designed to enhance the performance and durability of concrete structures. Our products include concrete admixtures, repair and protection solutions, waterproofing and sealing products, and more. With our expertise in construction chemicals, we can help you find the right solution for your specific needs.",
        "Fibers for Concrete & Plastering":
            "Elevate your concrete and plastering projects to new heights with the game-changing power of glass fibers. Discover a world of enhanced strength, durability, and versatility as you transform your structures with the unmatched reinforcement provided by glass fibers. Say goodbye to cracks and weaknesses as you embrace a new level of resilience and longevity. With glass fibers, your concrete and plastering work will be infused with strength, ensuring structures that stand the test of time.",
        "Material Testing Instruments":
            "Raise offers a range of high-quality material testing instruments that are designed to provide accurate and reliable measurements for a variety of materials. Our products include concrete test hammers, moisture meters, thermometers, and more. With our advanced testing instruments, you can ensure that your materials meet the required specifications and standards.",
        "Bathroom & Sanitary Fittings":
            "Sontuosità also offers a range of bathroom and sanitary fittings to add functionality and style to your bathroom. Our products are made using high-quality materials and are designed to provide the perfect balance between style and functionality. We offer a variety of bathroom fittings, including taps, showers, washbasins, toilets, and more, all available in different sizes and styles to suit your specific needs.",
        "Micro Silica": "Whether you're working on a residential, commercial, or industrial project, our Micro Silica will contribute to the longevity and structural integrity of your concrete. Its unique particle size distribution ensures optimal packing density and excellent pozzolanic reactivity, allowing you to achieve outstanding concrete performance in various applications.",
        "Concrete Pump Primer": "Maximize the efficiency and performance of your concrete pumping operations with our top-of-the-line Concrete Pump Primer by Raise. Engineered to lubricate and protect your concrete pump, our primer ensures smooth and reliable concrete flow, saving you time, effort, and resources on every job."
    }

    const categoryImages = {
        "Pronil Waterproofing Solutions": "https://i0.wp.com/www.pronil.com/wp-content/uploads/2020/01/Pronil-ACR-hydrophilic-waterstop-01.jpg?fit=360%2C240&ssl=1",
        "Carbon Fibers & Laminates": "https://www.thoughtco.com/thmb/HRRmPjoGygm28L1cprIsllJAlkY=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/24322793889_db8cc4450e_k-b1aa4260cd76435b97c953c992e58c93.jpg",
        "Construction Chemicals": "https://5.imimg.com/data5/LC/BX/MY-1969056/construction-chemicals-500x500.jpg",
        "Fibers for Concrete & Plastering": "https://trpreadymix.com/wp-content/uploads/2019/07/concrete-products-textile-fiber-reinforced-concrete-1024x682.jpeg",
        "Material Testing Instruments": "/playground_assets/testing.jpg",
        "Concrete Pump Primer": "/playground_assets/concrete-pump.jpg",
        "Micro Silica": "/playground_assets/micro-silica.jpg"
    }
  const [doesntExist, setdoesntExist] = useState(false)
  
  const params = useParams()
  console.log(params)

  function checkIfCategoryExists() {
    if (Object.keys(products).every(product => product !== params.product)) {
      if (Object.keys(products2).every(product => product !== params.product)) {
        setdoesntExist(true)
      }
    }
  }

  const [product, setproduct] = useState(products[params.product] || products2[params.product])
  const [uriOfSelectedProduct, seturiOfSelectedProduct] = useState(null)

  const [showLeadGenForm, setshowLeadGenForm] = useState(false)
  const [leadFormFilledFilled, setleadFormFilledFilled] = useState(false)

  function leadForm() {
    setshowLeadGenForm(true)
  }

  async function submitForm(){
    console.log("submitting form")
    console.log(document.getElementById("name").value)
    console.log(document.getElementById("email").value)
    console.log(document.getElementById("phone").value)
    const response = await fetch("https://dura-materials-default-rtdb.asia-southeast1.firebasedatabase.app/leads.json", {
      method: "POST",
      body: JSON.stringify({
        name: document.getElementById("name").value,
        email: document.getElementById("email").value,
        phone: document.getElementById("phone").value,
        product: product
      })
    })
    const data = await response.json()
    console.log(data)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    checkIfCategoryExists()
    console.log(doesntExist);
  }, [])
    
  return (
    <div style={{backgroundColor: '#111729ff'}}>
      <Helmet>
        <title>Product</title>
        <meta property="og:title" content="Product" />
        <meta property="og:description" content="Raise is a leading supplier of construction materials and services in India. We are a one-stop solution for all your construction needs." />
        <meta property="og:image" content="/playground_assets/raiseLogo.jpeg" />
      </Helmet>
      {
        showLeadGenForm ? (
      <div style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.6)",
        zIndex: 1000
      }}>
        <div style={{
          backgroundColor: "white",
          backgroundImage: "url(/playground_assets/bright-squares.png)",
          padding: "1.6rem",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          borderRadius: "0.3rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.8rem"
        }}
        className='dialog'
        >
          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <img src="/playground_assets/raiseLogo.jpeg" alt="" style={{
              height: "3rem",
            }} />
          <h2 style={{color: "black"}}>May we get to know you first?</h2>
          <h5 style={{color: "black"}}>Please fill in your details to download the brochure.</h5>
          </div>
          <label style={{color: "black", justifySelf: 'left', fontSize: "0.9rem", fontWeight: '500'}}>Name</label>
          <input required id='name' type="text" className='input' placeholder="Name" style={{padding: "1rem", paddingTop: "0.8rem", paddingBottom: "0.8rem", borderRadius: "0.3rem", border: "#e4e4e4 0.15em solid", outline: "none", flexGrow: 1}} />
          <label style={{color: "black", justifySelf: 'left', fontSize: "0.9rem", fontWeight: '500'}}>Email</label>
          <input required id="email" type="text" className='input' placeholder="Email" style={{padding: "1rem", paddingTop: "0.8rem", paddingBottom: "0.8rem", borderRadius: "0.3rem", border: "#e4e4e4 0.15em solid", outline: "none", flexGrow: 1}} />
          <label style={{color: "black", justifySelf: 'left', fontSize: "0.9rem", fontWeight: '500'}}>Phone</label>
          <input required id='phone' type="text" className='input' placeholder="Phone" style={{padding: "1rem", paddingTop: "0.8rem", paddingBottom: "0.8rem", borderRadius: "0.3rem", border: "#e4e4e4 0.15em solid", outline: "none", flexGrow: 1}} />
          <a href={uriOfSelectedProduct} target="_blank" rel="noreferrer">
          <button type='submit' className='btn' style={{background: "black", padding: "1rem", color: "white", marginTop: 10}} onClick={()=>{submitForm(); setleadFormFilledFilled(true); setshowLeadGenForm(false)}}>
            Download
          </button>
          </a>
        </div>
      </div>
        )
        :
        null
      }
        <Header />
        <div className="main" style={{paddingLeft: 50, paddingRight: 50, background: 'white'}}>
            <div className="upper" style={{display: 'flex', justifyContent: 'space-between', marginTop: 10, paddingTop: 90}}>
                <div className="upper__left">
                    <img src={categoryImages[params.category]} alt={`${params.category}'s Cover Image`} className='sep-cat-img' width={700} height={400} style={{objectFit: 'cover'}} />
                </div>
            <div className="upper_right" style={{marginBottom: 35}}>
                <h1>{params.category}</h1>
                      <span style={{display: 'flex', justifyContent: 'start'}}>
                         <img src="/playground_assets/star.png" width={20} style={{marginRight: 3}} height={20} alt="" />
                         <img src="/playground_assets/star.png" width={20} style={{marginRight: 3}} height={20} alt="" />
                         <img src="/playground_assets/star.png" width={20} style={{marginRight: 3}} height={20} alt="" />
                         <img src="/playground_assets/star.png" width={20} style={{marginRight: 3}} height={20} alt="" />
                         <img src="/playground_assets/star.png" width={20} height={20} alt="" />
                      </span>
                <p style={{lineHeight: 1.7}}>
                    {categoryDescriptions[params.category]}
                </p>
            </div>
        </div>
        {/* <div className="products-gallery"> */}
        {
        Object.keys(products).map((product, index) => (
          product===params.category && <div
            className="products-container"
            id={`product_${index+1}`}
            style={{marginTop: 0, alignItems: 'center'}}
            key={index}
          >
            <h1 className="products-text13" style={{color: 'black', textAlign: 'center'}}>Explore {product}</h1>
            <div className="products-gallery">
              {products[product].map((item, index) => (
                <GalleryCard1
                  url={item.url}
                  key={index}
                  product={product}
                  image_src={item.img}
                  title={item.name}
                  rootClassName={`rootClassName${index+1}`}
                  onClick={()=>{seturiOfSelectedProduct(item.url); setproduct(item.name); product === "Material Testing Instruments" ? leadForm() : null}}
                ></GalleryCard1>
              ))}
            </div>
          </div>
        ))
      }
      {
        Object.keys(products2).map((product, index) => (
            product===params.category && <div className="products-container" id={`product_${index+4}`} key={index}>
            <h1 className="products-text13">Our Collection of {product}</h1>
            <div className="products-gallery">
              {products2[product].map((item, index) => (
                <GalleryCard1
                  key={index}
                  image_src={item}
                  title={item.toString().split('/')[4].split(".")[0].split("-").join(" ")}
                  rootClassName={`rootClassName${index+4}`}
                ></GalleryCard1>
              ))}
            </div>
          </div>
        ))
      }
      {
        params.category==="Bathroom & Sanitary Fittings" || params.category==="Vitrified Tiles" ? <div className="products-container" style={{minHeight: 'fit-content'}}>
            <span style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                marginTop: 80,
                marginBottom: 60
            }}>
                <h1 style={{
                    fontSize: 40,
                    fontWeight: 500,
                    color: '#000'
                }}>This Category will be Launching Soon!!</h1>
            </span>
        </div> : <div></div>
        
      }
      
            {/* <GalleryCard1 image_src="https://www.bhutrastones.com/wp-content/uploads/2018/12/yellow-valencia-1-1024x683-Copy-300x300.jpg" rootClassName={1} /> */}
        {/* </div> */}
        </div>
        <Footer />
    </div>
  )
}

export default SingleCategory