import React, { useEffect } from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Counter from '../components/counter'
import PrimaryButton from '../components/primary-button'
import SecondaryButton from '../components/secondary-button'
import Footer from '../components/footer'
import './profile.css'
import MemberDetails from '../components/member-details'
import { Link } from 'react-router-dom'

const Profile = (props) => {

  useEffect(()=>{
    window.scrollTo(0,0)
  }, [])

  return (
    <div className="profile-container">
      <Helmet>
        <title>About - Raise</title>
        <meta property="og:title" content="About - Raise" />
        <meta property="og:description" content="Raise is a leading supplier of construction materials and services in India. We are a one-stop solution for all your construction needs." />
        <meta property="og:image" content="/playground_assets/raiseLogo.jpeg" />
      </Helmet>
      <div className="profile-image">
        <Header></Header>
        <img
          alt="image"
          src="/playground_assets/gray-vector.svg"
          className="profile-image1"
        />
        <div className="profile-bg"></div>
      </div>
      <div className="profile-container01">
        <div className="profile-container02">
          <div className="profile-container03">
            <Counter type="Years in Industry" number="10+"></Counter>
            <Counter type="Successful Services" number="100+"></Counter>
            <Counter type="Happy Clients" number="80+"></Counter>
          </div>
          <img
            alt="image"
            src="/playground_assets/raiseLogo.jpeg"
            className="profile-image2"
          />
          <div className="profile-container04">
            <div className="profile-container05">
              <Link to="/products">
              <PrimaryButton button="Products"></PrimaryButton>
              </Link>
            </div>
            <Link to="/contact">
            <SecondaryButton button="Contact"></SecondaryButton>
            </Link>
          </div>
        </div>
        {/* <div className='trial-div'> */}
        <div className="profile-container06">
          <h3 className="profile-text Text2XL">Raise</h3>
          <div className="profile-container07">
            <svg viewBox="0 0 1024 1024" className="profile-icon">
              <path d="M512 490q44 0 75-31t31-75-31-75-75-31-75 31-31 75 31 75 75 31zM512 86q124 0 211 87t87 211q0 62-31 142t-75 150-87 131-73 97l-32 34q-12-14-32-37t-72-92-91-134-71-147-32-144q0-124 87-211t211-87z"></path>
            </svg>
            <span className="profile-text1 TextSM">
              Bengaluru, Karnataka, India
            </span>
          </div>
          {/* <div className="profile-container08">
            <svg viewBox="0 0 1024 1024" className="profile-icon2">
              <path d="M810.667 213.333h-597.333l64-85.333h469.333zM929.877 230.059l-127.744-170.325c-8.363-11.136-21.077-17.024-34.133-17.067h-512c-13.909 0-26.283 6.656-34.133 17.067l-127.744 170.325c-1.835 2.389-3.456 5.035-4.736 7.808-2.773 5.803-4.096 12.032-4.053 18.133v597.333c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h597.333c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-597.333c0-9.344-3.029-18.005-8.064-24.96-0.171-0.213-0.299-0.427-0.469-0.64zM170.667 298.667h682.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-597.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165zM640 426.667c0 35.371-14.293 67.285-37.504 90.496s-55.125 37.504-90.496 37.504-67.285-14.293-90.496-37.504-37.504-55.125-37.504-90.496c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667c0 58.88 23.936 112.299 62.464 150.869s91.989 62.464 150.869 62.464 112.299-23.936 150.869-62.464 62.464-91.989 62.464-150.869c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z"></path>
            </svg>
          </div> */}
          
          <div className="profile-container11"></div>
          <span className="profile-text4">
            <span className="profile-text5">
            Raise is a leading Contracting, Procurement, and Trading Company based in Bengaluru, India. Established with a vision to provide expert services and supplies to the construction industry, Raise has been delivering high-quality solutions to its clients for several years.
<br /><br />
With a focus on innovation, Raise optimizes the use of cutting-edge technologies, coupled with local human and capital resources, to contribute to the development of the national and regional construction sectors. The company has also forged strategic alliances with some of the international reputed companies, which has helped it grow as a major force in the construction industry.
<br /><br />
At Raise, we believe in excellence and are committed to providing our clients with the best possible services. We have a team of highly skilled professionals and workers who are dedicated to achieving the success of every project we undertake. Our business is conducted in line with good international practices, and we provide safe and healthy working conditions for our employees.
<br /><br />
            </span>
            <span className='missionvision' style={{
              display: 'grid',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: '3rem',
              marginTop: '2rem',
              marginBottom: '2rem',
              "@media and screen (maxWidth: 768px)": {
                display: 'grid',
                gridTemplateColumns: 'repeat(1, 1fr)',
              }
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                // alignItems: 'center',
                textAlign: 'justify',
                color: "#1e293bff"
              }}>
              <span style={{
                fontWeight: '700',
                fontSize: 30,
                color: "#111729ff",
                alignSelf: 'center'
              }}>Mission</span>
              <span>At Raise, our mission is to provide our customers with innovative and high-quality construction materials and services that improve the durability and longevity of their buildings, structures, and infrastructure. We strive to exceed our customers' expectations by delivering exceptional value, reliability, and expertise, while also promoting sustainability and responsible environmental practices.</span>
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'justify',
                // alignItems: 'center',
                color: "#1e293bff"
              }}>
              <span style={{
                fontWeight: '700',
                fontSize: 30,
                color: "#111729ff",
                alignSelf: 'center'
              }}>Vision</span>
              <span>Our vision at Raise is to be a leading provider of advanced construction materials and solutions that address the complex challenges facing the global construction industry. We aim to constantly innovate and improve our products and services to meet the evolving needs of our customers, and to foster a culture of excellence, collaboration, and integrity throughout our organization.</span>
              </div>
            </span>
          </span>
          {/* <span className="profile-text6">
            <span>Show more</span>
            <span></span>
          </span> */}
        </div>
        {/* </div> */}

      </div>
      <Footer></Footer>
    </div>
  )
}

export default Profile
