import React, { useEffect } from 'react'
import './products.css'
import { Helmet } from 'react-helmet'
import Header from '../components/header'
import Footer from '../components/footer'
import { Link } from 'react-router-dom'

function Products() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className='products-page'>
            <Helmet>
                <title>Profile - Raise</title>
                <meta property="og:title" content="Profile - Raise" />
                <meta property="og:description" content="Raise is a leading supplier of construction materials and services in India. We are a one-stop solution for all your construction needs." />
        <meta property="og:image" content="/playground_assets/raiseLogo.jpeg" />
            </Helmet>
            <div className="products-catalog">
                <Header />
                <div className="products-catalog-content">
                    <h1>Special Construction Materials</h1>
                    <div className="special-cards">
                        {/* <Link to=""> */}
                        <a href="https://sontuosita.vercel.app/" className="special-card special-card1">
                            <div className="special-card-content">
                                <h3>Premium range of Interior Products</h3>
                                <button style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <span>Explore</span>
                                    <svg style={{width: 26, marginLeft: 8}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                                        <path style={{fill:"#232326"}} d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" dataName="Right"/>
                                    </svg>
                                    <svg version="1.1" id="icons_1_" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 128 128" style={{enableBackground:"new 0 0 128 128"}} xmlSpace="preserve">
                                        <g id="row1_1_">
                                            <g id="_x31__3_" style={{display: 'inline'}}>
                                                <path class="st2" style={{fill: '#000'}} d="M64 0C28.7 0 0 28.7 0 64s28.7 64 64 64 64-28.7 64-64S99.3 0 64 0zm0 121.6C32.2 121.6 6.4 95.8 6.4 64S32.2 6.4 64 6.4s57.6 25.8 57.6 57.6-25.8 57.6-57.6 57.6zM49.2 38.4 73.6 64 49.2 89.6h13.5L86.4 64 62.7 38.4H49.2z" id="_x32__2_"/>
                                                </g>
                                            </g>
                                        </svg>
                                </button>
                            </div>
                        </a>
                        {/* </Link> */}
                        <Link to="/products/Construction Chemicals" className="special-card special-card3">
                            <div className="special-card-content">
                                <h3>Construction Chemicals</h3>
                                <button style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <span>Explore</span>
                                    <svg style={{width: 26, marginLeft: 8}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                                        <path style={{fill:"#232326"}} d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" dataName="Right"/>
                                    </svg>
                                    <svg version="1.1" id="icons_1_" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 128 128" style={{enableBackground:"new 0 0 128 128"}} xmlSpace="preserve">
                                        <g id="row1_1_">
                                            <g id="_x31__3_" style={{display: 'inline'}}>
                                                <path class="st2" style={{fill: '#000'}} d="M64 0C28.7 0 0 28.7 0 64s28.7 64 64 64 64-28.7 64-64S99.3 0 64 0zm0 121.6C32.2 121.6 6.4 95.8 6.4 64S32.2 6.4 64 6.4s57.6 25.8 57.6 57.6-25.8 57.6-57.6 57.6zM49.2 38.4 73.6 64 49.2 89.6h13.5L86.4 64 62.7 38.4H49.2z" id="_x32__2_"/>
                                                </g>
                                            </g>
                                        </svg>
                                </button>
                            </div>
                        </Link>
                        {/* <Link to=""> */}
                        {/* </Link> */}
                        {/* <Link to=""> */}
                        {/* </Link> */}
                        {/* <Link to=""> */}
                        <Link to="/products/Fibers for Concrete & Plastering" className="special-card special-card4">
                            <div className="special-card-content" style={{backgroundColor: "rgba(0,0,0,0.5)"}}>
                                <h3>Fibers for Concrete & Plastering</h3>
                                <button style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <span>Explore</span>
                                    <svg style={{width: 26, marginLeft: 8}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                                        <path style={{fill:"#232326"}} d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" dataName="Right"/>
                                    </svg>
                                    <svg version="1.1" id="icons_1_" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 128 128" style={{enableBackground:"new 0 0 128 128"}} xmlSpace="preserve">
                                        <g id="row1_1_">
                                            <g id="_x31__3_" style={{display: 'inline'}}>
                                                <path class="st2" style={{fill: '#000'}} d="M64 0C28.7 0 0 28.7 0 64s28.7 64 64 64 64-28.7 64-64S99.3 0 64 0zm0 121.6C32.2 121.6 6.4 95.8 6.4 64S32.2 6.4 64 6.4s57.6 25.8 57.6 57.6-25.8 57.6-57.6 57.6zM49.2 38.4 73.6 64 49.2 89.6h13.5L86.4 64 62.7 38.4H49.2z" id="_x32__2_"/>
                                                </g>
                                            </g>
                                        </svg>
                                </button>
                            </div>
                        </Link>
                        {/* </Link> */}
                        <Link to="/products/Concrete Pump Primer" className="special-card special-card5">
                            <div className="special-card-content" style={{backgroundColor: "rgba(0,0,0,0.5)"}}>
                                <h3>Concrete Pump Primer</h3>
                                <button style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <span>Explore</span>
                                    <svg style={{width: 26, marginLeft: 8}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                                        <path style={{fill:"#232326"}} d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" dataName="Right"/>
                                    </svg>
                                    <svg version="1.1" id="icons_1_" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 128 128" style={{enableBackground:"new 0 0 128 128"}} xmlSpace="preserve">
                                        <g id="row1_1_">
                                            <g id="_x31__3_" style={{display: 'inline'}}>
                                                <path class="st2" style={{fill: '#000'}} d="M64 0C28.7 0 0 28.7 0 64s28.7 64 64 64 64-28.7 64-64S99.3 0 64 0zm0 121.6C32.2 121.6 6.4 95.8 6.4 64S32.2 6.4 64 6.4s57.6 25.8 57.6 57.6-25.8 57.6-57.6 57.6zM49.2 38.4 73.6 64 49.2 89.6h13.5L86.4 64 62.7 38.4H49.2z" id="_x32__2_"/>
                                                </g>
                                            </g>
                                        </svg>
                                </button>
                            </div>
                        </Link>
                        <Link to="/products/Carbon Fibers & Laminates" className="special-card special-card2">
                            <div className="special-card-content">
                                <h3>Carbon Fibers & Laminates</h3>
                                <button style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <span>Explore</span>
                                    <svg style={{width: 26, marginLeft: 8}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                                        <path style={{fill:"#232326"}} d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" dataName="Right"/>
                                    </svg>
                                    <svg version="1.1" id="icons_1_" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 128 128" style={{enableBackground:"new 0 0 128 128"}} xmlSpace="preserve">
                                        <g id="row1_1_">
                                            <g id="_x31__3_" style={{display: 'inline'}}>
                                                <path class="st2" style={{fill: '#000'}} d="M64 0C28.7 0 0 28.7 0 64s28.7 64 64 64 64-28.7 64-64S99.3 0 64 0zm0 121.6C32.2 121.6 6.4 95.8 6.4 64S32.2 6.4 64 6.4s57.6 25.8 57.6 57.6-25.8 57.6-57.6 57.6zM49.2 38.4 73.6 64 49.2 89.6h13.5L86.4 64 62.7 38.4H49.2z" id="_x32__2_"/>
                                                </g>
                                            </g>
                                        </svg>
                                </button>
                            </div>
                        </Link>
                        <Link to="/products/Micro Silica" className="special-card special-card6">
                            <div className="special-card-content" style={{backgroundColor: "rgba(0,0,0,0.5)"}}>
                                <h3>Micro Silica</h3>
                                <button style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <span>Explore</span>
                                    <svg style={{width: 26, marginLeft: 8}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                                        <path style={{fill:"#232326"}} d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" dataName="Right"/>
                                    </svg>
                                    <svg version="1.1" id="icons_1_" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 128 128" style={{enableBackground:"new 0 0 128 128"}} xmlSpace="preserve">
                                        <g id="row1_1_">
                                            <g id="_x31__3_" style={{display: 'inline'}}>
                                                <path class="st2" style={{fill: '#000'}} d="M64 0C28.7 0 0 28.7 0 64s28.7 64 64 64 64-28.7 64-64S99.3 0 64 0zm0 121.6C32.2 121.6 6.4 95.8 6.4 64S32.2 6.4 64 6.4s57.6 25.8 57.6 57.6-25.8 57.6-57.6 57.6zM49.2 38.4 73.6 64 49.2 89.6h13.5L86.4 64 62.7 38.4H49.2z" id="_x32__2_"/>
                                                </g>
                                            </g>
                                        </svg>
                                </button>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="products-banner">
                    <div className='trial-div2' style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        background: 'rgba(0, 0, 0, 0.8)',
                        color: 'white',
                        padding: '48px',
                        margin: 0
                    }}>
                    <div className="products-container15">
                        <h1 className="products-text21">
                            <span>Our Range of Material Testing Instruments</span>
                        </h1>
                        <span className="products-text30">
                            <span>
                                <span>
                                    Our Material Testing Instruments
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: ' ',
                                        }}
                                    />
                                </span>
                                <span>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: ' ',
                                        }}
                                    />
                                </span>
                            </span>
                            <span>

                            </span>
                        </span>
                        <div className="products-container16">
                            <Link to="/products/Material Testing Instruments">
                            <button className="products-button button" style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: 14,
                                cursor: 'pointer',
                            }}>
                                Explore Instruments
                                {/* <svg xmlns="http://www.w3.org/2000/svg" style={{
                    width: 10,
                    height: 10,
                }}><path style={{
                }} d="M10 20A10 10 0 1 0 0 10a10 10 0 0 0 10 10zM8.711 4.3l5.7 5.766L8.7 15.711l-1.4-1.422 4.289-4.242-4.3-4.347z"/></svg> */}
                                <img src={require('./right.png')} style={{
                                    width: 25,
                                    height: 25,
                                    marginLeft: 15
                                }} alt="" />
                            </button>
                            </Link>
                        </div>
                    </div>
                    <img
                        alt="image"
                        src="/playground_assets/testing.jpg"
                        className="products-image1"
                    />
                    </div>
                </div>
                <h1 style={{
                    backgroundColor: '#fff',
                    color: '#222',
                    padding: '20px',
                    paddingTop: '40px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    borderTop: '1px solid #bbb',
                }}>General Construction Materials</h1>
                <div className="products-gallery1">
                    <div className="products-container02">
                        <div className="products-container03">
                            <h1 className="products-text13">Ready Mix Concrete</h1>
                            <span className="products-text14">
                                <span>Our expertly mixed concrete provides durable, long-lasting results for any construction project.</span>
                            </span>
                        </div>
                    </div>
                    <div className="products-container04">
                        <div className="products-container05">
                            <h1 className="products-text16">Aggregates - 40 mm, 20 mm, 12 mm</h1>
                            <span className="products-text17">
                                <span>High-performing, sustainable aggregates offer exceptional value for your construction investment.</span>
                            </span>
                        </div>
                    </div>
                    <div className="products-container06">
                        <div className="products-container07">
                            <h1 className="products-text19">Crushed Stone Sand (M-Sand)</h1>
                            <span className="products-text20">
                                <span>Our reliable, strong foundation materials are essential for any construction project.</span>
                            </span>
                        </div>
                    </div>
                    <div className="products-container08">
                        <div className="products-container09">
                            <h1 className="products-text22">Plastering Sand (P-Sand)</h1>
                            <span className="products-text23">
                                <span>Smooth and even finish adds a professional touch to any construction project.</span>
                            </span>
                        </div>
                    </div>
                    <div className="products-container10">
                        <div className="products-container11">
                            <h1 className="products-text25">Granulated Sub Base</h1>
                            <span className="products-text26">
                                <span>Our strong base materials ensure safe and long-lasting roads and pavements.</span>
                            </span>
                        </div>
                    </div>
                    <div className="products-container12">
                        <div className="products-container13">
                            <h1 className="products-text28">Wet Mix Macadum</h1>
                            <span className="products-text29">
                                <span>Our durable, reliable materials are ideal for heavy-duty construction projects.</span>
                            </span>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Products