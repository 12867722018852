import React, { useEffect } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import styles from './services.module.css'
import Gallery2 from '../components/gallery-2'
import BlogPostCard4 from '../components/BlogPostCard4'
import { Helmet } from 'react-helmet'

function Services() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

  return (
    <div style={{
        backgroundImage: "url('/playground_assets/asfalt-dark.png')",
    }}>
        <Helmet>
        <title>Services</title>
        <meta property="og:title" content="Services" />
        <meta property="og:description" content="Raise is a leading supplier of construction materials and services in India. We are a one-stop solution for all your construction needs." />
        <meta property="og:image" content="/playground_assets/raiseLogo.jpeg" />
      </Helmet>
        <div style={{
            backgroundImage: "url('/playground_assets/constr1.jpg')",
            backgroundSize: 'cover',
        }}>
            <div style={{
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                height: '100%',
                zIndex: 10,
            }}>
                <Header />
                <div style={{
                    width: '100%',
                    borderBottom: '0.1px solid #e4e4e41f',
                }}></div>
            </div>
          </div>
          <h1 style={{
            //   color: 'white',
            color: 'black',
              textAlign: 'center',
            //   paddingTop: 60,
            paddingTop: 30
          }}>Our Services</h1>
          {/* <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: 30,
                paddingBottom: 50,
                paddingLeft: 40,
                paddingRight: 40,
                gap: 40,
                background: "linear-gradient(rgba(0,0,0,0.0), rgba(0,113,20,0.2))",
          }}>
            <div
                style={{
                backgroundColor: 'white',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: '100% !important',
                width: '100%',
                position: 'absolute',
                top: 0,
                zIndex: -1,
                }}
            >
<br /><br /><br /><br /><br />
<br /><br /><br /><br /><br />
<br /><br /><br /><br /><br />
<br /><br /><br /><br /><br />
<br /><br /><br /><br /><br />
<br /><br /><br /><br /><br />
<br /><br /><br /><br /><br />
            </div>
              <BlogPostCard4
                id={1}
                  date="Consultancy Services"
                  description={()=>{
                    return (
                        <ol style={{
                            textAlign: 'left',
                        }}>
                           <li>For quality control of RMCs and project plants</li> 
                           <li>For RMC and aggregate business</li>
                           <li>For QCI quality scheme</li>
                           <li>For QCI quality scheme</li>
                        </ol>
                    )
                  }}
                  label="Business &amp; Travel"
                  rootClassName="rootClassNam"
                  image_src="/playground_assets/consult1.jpg"
                  ></BlogPostCard4>
                  <BlogPostCard4
                  id={2}
                  date="Industrial Flooring"
                    description={()=>{
                        return (
                            <ol style={{
                                textAlign: 'left',
                            }}>
                                <li>Laser level flooring technology</li>
                                <li>Coloured concrete flooring</li>
                                <li>Concrete flooring densification</li>
                                <li>Polished concrete flooring</li>
                            </ol>
                        )
                    }}
                      label="Nature"
                      image_src="/playground_assets/flooring1.jpg"
                  ></BlogPostCard4>
                  <BlogPostCard4
                  id={3}
                  date="Training"
                    description={()=>{
                        return (
                            <ol style={{
                                textAlign: 'left',
                            }}>
                                <li>In the field of Concrete Technology</li>
                                <li>In the field of Ready mixed Concrete</li>
                                <li>In the field of Chemical Admixtures</li>
                            </ol>
                        )
                    }}
                      image_src="/playground_assets/training.jpg"
                  ></BlogPostCard4>
              <BlogPostCard4
              id={4}    
                    date="Construction Services"
                    description={()=>{
                        return (
                            <ol style={{
                                textAlign: 'left',
                            }}>
                                <li>Customer Centric, Hassle Free</li>
                            <li>100% Quality Assurance</li>
                            <li>Complete Transparency</li>
                            <li>In-Time Project Completion</li>
                            </ol>
                        )
                    }}
                  image_src="/playground_assets/constr1.gif"
              ></BlogPostCard4>
              <BlogPostCard4
              id={5}    
                    date="Waterproofing Services"
                    description={()=>{
                        return (
                            <ol style={{
                                textAlign: 'left',
                            }}>
                                <li>Expert services for your home or business.</li>
                            <li>100% Quality Assurance</li>
                            <li>Transform your space with our products.</li>
                            <li>Trusted by customers around the world.</li>
                            </ol>
                        )
                    }}
                  image_src="/playground_assets/waterproof.webp"
              ></BlogPostCard4>
          </div> */}
          <Gallery2 />
          <div style={{
            height: 60,
            width: '100%',
            backgroundColor: '#fff',
        backgroundImage: "url('/playground_assets/asfalt-dark.png')",
    }}></div>
        <Footer />
    </div>
  )
}

export default Services